import React, { FC } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useUser } from 'hooks/useUser'

const PrivateRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const user = useUser()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && user.jwt ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
