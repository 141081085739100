import { useSelector, useDispatch } from 'react-redux'
import { useApi } from './useApi'
import { setTreatments, getAllTreatments } from 'ducks/treatments'
import { useCallback } from 'react'

export const useTreatments = () => {
  const dispatch = useDispatch()
  const [getTreatmentsRequest, { loading }] = useApi(
    'GET',
    '/doctor/treatments'
  )

  const fetchTreatments = useCallback(
    async (params?: any) => {
      const res = await getTreatmentsRequest({ params })
      if (res?.data?.doctorTreatments) {
        dispatch(setTreatments(res.data.doctorTreatments))
      }
    },
    [getTreatmentsRequest]
  )

  return {
    treatments: useSelector(getAllTreatments),
    fetchTreatments,
    loading,
  }
}
