import React, { FC } from 'react'

type Props = {
  step: number
  active: boolean
  onClick: (step: number) => void
}

const DayBubble: FC<Props> = ({ step, active, onClick }) => {
  return (
    <div
      className={`dayBubble ${active && 'active'}`}
      onClick={() => {
        onClick(step)
      }}
    >
      {`Step ${step}`}
    </div>
  )
}

export default DayBubble
