import {
  createNotification,
  Notification,
  getNotification,
  deleteNotification,
} from 'ducks/notifications'
import { useDispatch, useSelector } from 'react-redux'

export const useToast = () => {
  const dispatch = useDispatch()
  const showToast = (notification: Notification) => {
    dispatch(createNotification(notification))
  }
  const hideToast = () => {
    dispatch(deleteNotification())
  }
  return {
    showToast,
    hideToast,
    notification: useSelector(getNotification),
  }
}
