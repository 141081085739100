import React, { FC, useState } from 'react'
import { Question, QuestionTypeNames } from 'models/Survey'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

type Props = {
  question: Question
  onDelete: (question: Question) => void
  onEdit: (question: Question) => void
}

const QuestionBox: FC<Props> = ({ question, onDelete, onEdit }) => {
  const [optionsVisible, setOptionsVisible] = useState(false)

  return (
    <li className='flex row surveyQuestion'>
      <div className='flex row flexAuto listItemWrapper'>
        <div
          className={`dropdownBackdrop ${optionsVisible && 'active'}`}
          onClick={() => setOptionsVisible(!optionsVisible)}
        ></div>
        <div className='flex column flexAuto'>
          <h4>{question.question_name}</h4>
          <p>
            {`${QuestionTypeNames[question.question_type]} ${
              question.question_type_params?.to || ''
            }`}
          </p>
        </div>
        <div className='flex column justify-center whiteBoxEditWrapper'>
          <div>
            <div
              onClick={() => {
                setOptionsVisible(!optionsVisible)
              }}
            >
              <FontAwesomeIcon
                size='lg'
                color='rgba(0,0,0,.3)'
                icon={faEllipsisH}
              />
            </div>
            <div className={`dropdownWrapper ${optionsVisible && 'active'}`}>
              <ul>
                <li
                  onClick={() => {
                    setOptionsVisible(!optionsVisible)
                    onEdit(question)
                  }}
                >
                  Edit
                </li>
                <li
                  onClick={() => {
                    setOptionsVisible(!optionsVisible)
                    onDelete(question)
                  }}
                >
                  Delete
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default QuestionBox
