import React, { FC, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTh } from '@fortawesome/free-solid-svg-icons'
import Session, { SESSION_TYPE_NAMES } from 'models/Session'
import { TreatmentType } from '../TreatmentManager'

type Props = {
  item: Session
  position: number
  treatmentType: TreatmentType
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session) => void
}

const SessionTile: FC<Props> = ({
  item,
  position,
  treatmentType,
  onEditSession,
  onDeleteSession,
}) => {
  const [editSession, setEditSession] = useState(false)
  const id = `${item.title}-${position}`

  return (
    <Draggable key={id} draggableId={id} index={position}>
      {(provided) => (
        <div
          className='flex row flexAuto listItemWrapper align-center'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={
              editSession ? 'dropdownBackdrop active' : 'dropdownBackdrop'
            }
            onClick={() => setEditSession(!editSession)}
          ></div>
          <div className='flex column dragIconWrapper'>
            <FontAwesomeIcon size='lg' color='rgba(0,0,0,.3)' icon={faTh} />
          </div>
          <div className='flex column flexAuto'>
            <h4>{item.title}</h4>
          </div>
          <div className='flex column flex-grid-2'>
            <p>{SESSION_TYPE_NAMES[Number(item.session_type_id)]}</p>
          </div>
          <div className='flex column justify-center whiteBoxEditWrapper'>
            <div>
              <div onClick={() => setEditSession(!editSession)}>
                <FontAwesomeIcon
                  size='lg'
                  color='rgba(0,0,0,.3)'
                  icon={faEllipsisH}
                />
              </div>
              <div
                className={
                  editSession ? 'dropdownWrapper active' : 'dropdownWrapper'
                }
              >
                <ul>
                  {(treatmentType === 'scheduled' || !item.isExisting) && (
                    <li
                      onClick={() => {
                        onEditSession(item)
                        setEditSession(!editSession)
                      }}
                    >
                      Edit
                    </li>
                  )}
                  <li
                    onClick={() => {
                      onDeleteSession(item)
                      setEditSession(!editSession)
                    }}
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default SessionTile
