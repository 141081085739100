import React, { FC } from 'react'
import Session from 'models/Session'
import ScheduledDay from './ScheduledDay'
import { TreatmentStep } from '../TreatmentManager'

type Props = {
  sessions: TreatmentStep
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session, step: number) => void
  importSession: (step: number) => void
  createSession: (step: number) => void
  onReorderSessions: (
    step: number,
    startIndex: number,
    endIndex: number
  ) => void
}

const NonScheduledTreatment: FC<Props> = ({
  sessions,
  onDeleteSession,
  onEditSession,
  importSession,
  createSession,
  onReorderSessions,
}) => {
  return (
    <div className='flex column flex-grid-12 last-flex'>
      <ScheduledDay
        step={sessions}
        treatmentType='normal'
        importSession={importSession}
        createSession={createSession}
        onDeleteSession={onDeleteSession}
        onEditSession={onEditSession}
        onReorderSessions={onReorderSessions}
      />
    </div>
  )
}

export default NonScheduledTreatment
