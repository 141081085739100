import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Patient from 'models/Patient'

export type PatientsState = {
  allPatients: Patient[]
}

const initialState: PatientsState = {
  allPatients: [],
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients: (state: PatientsState, action: PayloadAction<Patient[]>) => {
      state.allPatients = action.payload
    },
  },
})

// Selectors:
export const getPatients = (state: RootState): Patient[] =>
  state.patients.allPatients

// Exports
const { actions, reducer } = patientsSlice
export const { setPatients } = actions
export default reducer
