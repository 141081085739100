import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Choice, Path } from 'models/Survey'

type Props = {
  choice: Choice
  canDelete: boolean
  index: number
  currentPathId: number
  isConditional?: boolean
  possiblePaths?: Path[]
  onChoiceChange: (choice: Choice, index: number) => void
  onDeleteChoice: (index: number) => void
}

const ChoiceItem: FC<Props> = ({
  choice,
  canDelete,
  index,
  currentPathId,
  isConditional,
  possiblePaths,
  onChoiceChange,
  onDeleteChoice,
}) => {
  const onNameChange = (e) => {
    onChoiceChange({ ...choice, choice: e.target.value }, index)
  }

  const onPathChange = (e) => {
    onChoiceChange(
      {
        ...choice,
        from_choice_path_id: currentPathId,
        to_choice_path_id: Number(e.target.value),
      },
      index
    )
  }

  return (
    <div
      className='flex column multiChoicesWrapper'
      key={'multiChoice' + index}
    >
      <div className='flex row'>
        <div
          className={
            isConditional
              ? canDelete
                ? 'flex column flex-grid-5 last-flex'
                : 'flex column flex-grid-6 last-flex'
              : 'flex column flexAuto'
          }
        >
          <div className='flex column inputWrapper'>
            <label htmlFor={'choice' + index}>{`Choice ${index + 1}`}</label>
            <input
              type='text'
              name={`choice-${index}`}
              id={`choice-${index}`}
              placeholder='What do you want this choice to say'
              onChange={onNameChange}
              value={choice.choice}
            />
          </div>
        </div>
        {isConditional && (
          <div className='flex column flex-grid-6 last-flex'>
            <div className='flex column inputWrapper'>
              <label htmlFor={`choiceSelection-${index}`}>{'Leads to'}</label>
              <select onChange={onPathChange} value={choice.to_choice_path_id}>
                <option>Path</option>
                {possiblePaths.map((path) => (
                  <option value={path.id}>{`Path ${path.id + 1}`}</option>
                ))}
              </select>
            </div>
          </div>
        )}
        {canDelete && (
          <div
            className='flex flex-grid-1 last-flex align-center justify-center deleteWrapper'
            onClick={() => onDeleteChoice(index)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChoiceItem
