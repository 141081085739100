import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Media from 'models/Media'

export type MediaState = {
  allMedia: Media[]
}

const initialState: MediaState = {
  allMedia: [],
}

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMedia: (state: MediaState, action: PayloadAction<Media[]>) => {
      state.allMedia = action.payload
    },
  },
})

// Selectors:
export const getAllMedia = (state: RootState): Media[] => state.media.allMedia

// Exports
const { actions, reducer } = mediaSlice
export const { setMedia } = actions
export default reducer
