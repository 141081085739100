import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from './store'

export type Notification = {
  name: string
  value: string
}

type NotificationPayload = {
  notification: Notification
  timeoutId: number
}

export type NotificationsState = {
  notification: Notification
  timeoutId: number
}

const initialState: NotificationsState = {
  notification: null,
  timeoutId: null,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createNotification: (
      state: NotificationsState,
      action: PayloadAction<NotificationPayload>
    ) => {
      if (state.timeoutId) clearTimeout(state.timeoutId)
      state.notification = action.payload.notification
      state.timeoutId = action.payload.timeoutId
    },
    deleteNotification: (state: NotificationsState) => {
      if (state.timeoutId) clearTimeout(state.timeoutId)
      return initialState
    },
  },
})

// Selectors:
export const getNotification = (state: RootState): Notification =>
  state.notifications.notification

// Exports
const { actions, reducer } = notificationsSlice
export const { deleteNotification } = actions
export default reducer

// Thunks
export const createNotification = (notification: Notification): AppThunk => (
  dispatch
) => {
  dispatch(
    actions.createNotification({
      notification,
      timeoutId: window.setTimeout(() => {
        dispatch(deleteNotification())
      }, 5000),
    })
  )
}
