import { useSelector, useDispatch } from 'react-redux'
import { getPatients, setPatients } from 'ducks/patients'
import { useApi } from './useApi'
import { useCallback } from 'react'

export const usePatients = () => {
  const dispatch = useDispatch()

  const [getPatientsRequest, { loading }] = useApi('GET', '/doctor/patients')

  const fetchPatients = useCallback(
    async (params?) => {
      const patientsRes = await getPatientsRequest({ params })
      if (patientsRes?.data) {
        dispatch(setPatients(patientsRes.data))
      }
    },
    [getPatientsRequest]
  )

  return {
    patients: useSelector(getPatients),
    fetchPatients,
    loading,
  }
}
