import { useEffect, useRef } from 'react'

// A helper hook that will skip execution upon mounting the component
// and will only execute upon actual updates of deps

const useDidUpdateEffect = (func, deps: any[]) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      return func()
    } else {
      didMount.current = true
    }
  }, deps)
}

export default useDidUpdateEffect
