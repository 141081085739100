import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Treatment from 'models/Treatment'

export type TreatmentsState = {
  allTreatments: Treatment[]
}

const initialState: TreatmentsState = {
  allTreatments: [],
}

const treatmentsSlice = createSlice({
  name: 'treatments',
  initialState,
  reducers: {
    setTreatments: (
      state: TreatmentsState,
      action: PayloadAction<Treatment[]>
    ) => {
      state.allTreatments = action.payload
    },
  },
})

// Selectors:
export const getAllTreatments = (state: RootState): Treatment[] =>
  state.treatments.allTreatments

// Exports
const { actions, reducer } = treatmentsSlice
export const { setTreatments } = actions
export default reducer
