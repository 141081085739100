import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Survey from 'models/Survey'

export type SurveysState = {
  allSurveys: Survey[]
}

const initialState: SurveysState = {
  allSurveys: [],
}

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    setSurveys: (state: SurveysState, action: PayloadAction<Survey[]>) => {
      state.allSurveys = action.payload
    },
  },
})

// Selectors:
export const getAllSurveys = (state: RootState): Survey[] =>
  state.surveys.allSurveys

// Exports
const { actions, reducer } = surveysSlice
export const { setSurveys } = actions
export default reducer
