import React, { FC } from 'react'
import Media from 'models/Media'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useToast } from 'hooks/useToast'

type Props = {
  item: Media
}

const MediaItem: FC<Props> = ({ item }) => {
  const { showToast } = useToast()
  let file = null
  if (item.type === 'image') {
    file = <img src={item.url} alt={item.name} />
  } else if (item.type === 'video') {
    file = (
      <video width='305' controls>
        <source type='video/mp4' src={item.url} />
      </video>
    )
  }
  return (
    <div className='flex flex-grid-3 column whitebox fileWrapper'>
      <div className='flex filePreview align-center'>{file}</div>
      <div className='flex row align-center fileMetaData'>
        <div className='flex flexAuto column'>
          <h4>{item.name}</h4>
          <p>
            <small>
              {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
            </small>
          </p>
        </div>
        <div>
          <CopyToClipboard
            text={item.url}
            onCopy={() => {
              showToast({
                name: 'Success',
                value: `Successfully copied file URL`,
              })
            }}
          >
            <FontAwesomeIcon size='lg' color='rgba(0,0,0,.3)' icon={faCopy} />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  )
}

export default MediaItem
