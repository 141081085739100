import React, { FC, useState } from 'react'
import Input from 'components/Input'
import { useToast } from 'hooks/useToast'
import { useApi } from 'hooks/useApi'
import 'assets/styles/fileUploadModal.scss'

type Props = {
  onUploadSuccess: () => void
  onClose: () => void
}

const FileUploadModal: FC<Props> = ({ onClose, onUploadSuccess }) => {
  const { showToast } = useToast()
  const [name, setName] = useState('')
  const [file, setFile] = useState<File>()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadFileRequest, { loading: uploading }, cancelUpload] = useApi(
    'POST',
    '/asset'
  )

  const uploadFile = async (file: File, name: string) => {
    const fileForm = new FormData()
    fileForm.append('name', name)
    fileForm.append('asset', file)
    const res = await uploadFileRequest({
      body: fileForm,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setUploadProgress(percentCompleted)
      },
    })
    if (res?.data?.asset_url) {
      showToast({
        name: 'Success',
        value: 'Your file was successfully uploaded',
      })
      onUploadSuccess()
      onClose()
    }
  }

  const close = () => {
    cancelUpload('Upload was cancelled')
    onClose()
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    setFile(files[0])
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setName(value)
  }

  const onUploadFile = () => {
    if (!file) {
      showToast({
        name: 'Error',
        value: 'Please select a file.',
      })
      return
    }
    if (!name) {
      showToast({
        name: 'Error',
        value: 'Please enter name.',
      })
      return
    }
    uploadFile(file, name)
  }

  return (
    <div className={'flex column modal active'}>
      <div onClick={close} className='flex column modalBackdrop'></div>
      <div className='flex column modalContent'>
        <div className='flex column flexAuto modalContentContainer'>
          <div className='flex column modalHead'>
            <h2>Upload a Media File</h2>
            <p>
              You can upload a video or image file in order to be able to use it
              in sessions
            </p>
          </div>
          <Input
            label='Name'
            type='text'
            name='name'
            disabled={uploading}
            placeholder='File name'
            value={name}
            onChange={onNameChange}
          />
          <Input
            label='Media File'
            type='file'
            disabled={uploading}
            name='asset'
            onChange={onFileChange}
          />
          {uploading && (
            <div className='progressWrapper'>
              <div className='flex row'>
                <p>Uploading</p>
                <p className='percents'>{`${uploadProgress}%`}</p>
              </div>
              <div className='flex progressLine'>
                <div
                  className='progress'
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='flex row sessionActionRow'>
          <div className='flex column flexAuto'>
            <button className='btn btnGrey' onClick={close}>
              Cancel
            </button>
          </div>
          <div className='flex column'>
            <button
              className='btn btnGreen'
              disabled={uploading}
              onClick={onUploadFile}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileUploadModal
