import React, { FC, useEffect } from 'react'
import { useAllMedia } from 'hooks/useAllMedia'
import ReactLoading from 'react-loading'
import 'assets/styles/fileImportModal.scss'

type Props = {
  active: boolean
  onClose: () => void
  onImport: (url: string, title: string) => void
}

const FileImportModal: FC<Props> = ({ active, onClose, onImport }) => {
  const { allMedia, fetchAllMedia, loading } = useAllMedia()

  useEffect(() => {
    fetchAllMedia()
  }, [])

  const filesArray = allMedia.map((item) => {
    if (item.type !== 'video') {
      return null
    }
    return (
      <div className='flex flex-grid-3 column fileWrapper'>
        <div className='flex filePreview align-center'>
          <video width='180' controls>
            <source type='video/mp4' src={item.url} />
          </video>
        </div>
        <div
          className='flex row align-center fileMetaData'
          onClick={() => onImport(item.url, item.name)}
        >
          <div className='flex flexAuto column'>
            <h5>{item.name}</h5>
            <p>
              <small>
                {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
              </small>
            </p>
          </div>
          <div></div>
        </div>
      </div>
    )
  })

  return (
    <div
      className={`flex column modal justify-center align-center ${
        active && 'active'
      }`}
    >
      <div onClick={onClose} className='flex column modalBackdrop'></div>
      <div className='flex column modalContent flex-grid-6 last-flex'>
        <h2>Import a Video to this Session</h2>
        <p>Make sure you have uploaded the video to the Media Library</p>
        {loading && (
          <div className='flex justify-center'>
            <ReactLoading type='bars' color='#548AF4' />
          </div>
        )}
        <div className='flex row flexWrap fileImportModal'>{filesArray}</div>
        <div className='flex row sessionActionRow'>
          <div className='flex column flexAuto'>
            <button className='btn btnGrey' onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileImportModal
