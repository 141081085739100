import React, { FC } from 'react'
import { Notification } from 'ducks/notifications'

type Props = {
  notification: Notification
  onClick: () => void
}

const NotificationToast: FC<Props> = ({ notification, onClick }) => {
  const isError = notification.name.toLowerCase() === 'error'
  return (
    <div
      className={`flex row flexAuto notificationBar ${isError && 'errorBar'}`}
    >
      <div className='flex column flexAuto justify-center '>
        <p>{notification.value || 'Something just happened'}</p>
      </div>
      <div className='flex row notificationsActions flexAuto justify-end'>
        <button className='btn' onClick={onClick}>
          Got it
        </button>
      </div>
    </div>
  )
}

export default NotificationToast
